import RenderableError, { ErrorButton, RenderableFields } from '../interfaces/RenderableError';

export default abstract class ScannerError extends Error implements RenderableError {
  information?: string;
  button?: ErrorButton;
  isSoft?: boolean;

  constructor(message: string, additional?: RenderableFields) {
    super();
    this.message = message;
    this.information = additional?.information;
    this.button = additional?.button;
    this.isSoft = additional?.isSoft;
    this.name = this.constructor.name;
    this.stack = new Error().stack;
  }
}
