import styles from './App.module.scss';
import { HoldingPage } from './components';
import { browser } from './util/browser';

export default function App() {
  return (
    <div className={styles.app}>
      <header>
        <h1>M&S BARCODE SCANNER</h1>
      </header>
      <main>
        <HoldingPage isIos={browser?.os === 'iOS'} />
      </main>
    </div>
  );
}
