import PhoneSVG from './PhoneSVG';

export default function HoldingPage({ isIos }: { isIos?: boolean }) {
  const appsLink = isIos
    ? 'https://apps.apple.com/gb/app/m-s-fashion-food-homeware/id538410698'
    : 'https://play.google.com/store/apps/details?id=com.marksandspencer.app&hl=en_GB';

  return (
    <>
      <PhoneSVG />
      <h1>Sorry, but M&S Barcode Scanner is now an app only experience</h1>
      <p>
        Please download the app to make shopping for your favourite M&S products easier than ever,
        whether you are at home, on the go or in store.
      </p>

      <button type="button">
        <a href={appsLink} style={{ textDecoration: 'none', color: 'black' }}>
          Download the M&S app
        </a>
      </button>
    </>
  );
}
