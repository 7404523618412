import { detect } from 'detect-browser';
import Quagga from 'quagga';

/**
 * @param opened Function to run when the browser tab/window is reopened.
 * @param closed Function to run when the browser tab/window is closed.
 * @return Cleanup function, to remove the event handler.
 */
export const onTabChange = ({ opened, closed }: { opened(): void; closed(): void }) => {
  const handler = () => (document.hidden ? closed() : opened());
  document.addEventListener('visibilitychange', handler);

  return () => document.removeEventListener('visibilitychange', handler);
};

/**
 * Runs the supplied function when the user navigates back to the site.
 *
 * @return Cleanup function, to remove the event handler.
 */
export const onBack = (eventHandler: () => void) => {
  const handler = (event: PageTransitionEvent) => event.persisted && eventHandler();
  window.addEventListener('pageshow', handler, false);

  return () => window.removeEventListener('pageshow', handler);
};

export const onRotation = (handler: () => void) => {
  // Would normally want an onResize() fallback here... but this can effectively be part of the
  // browser detection - with the hope that by the time window.orientation is fully-deprecated,
  // these browser-specific problems aren't still a thing!
  if (typeof window.orientation !== 'undefined')
    window.addEventListener('orientationchange', handler);
};

export const browser = detect();

/**
 * Use to defer expensive actions when opening or closing the keyboard (e.g. input.focus()/.blur())
 * on mobile devices.
 */
export const KEYBOARD_ACTION_LATENCY = browser?.os === 'Android OS' ? 200 : 0;

export const browserSupportsCSSGrid = () => {
  const testSubject = document.createElement('div');
  testSubject.style.display = 'grid';

  return testSubject.style.display === 'grid';
};

/**
 * Returns false, if any of the supplied strings are not defined on the window object (logging the
 * offenders with console.warn()); true, otherwise.
 */
export const browserHasFeatures = (...features: string[]) => {
  const missing = features.filter(
    feature =>
      !feature
        .split('.') // @ts-ignore
        .reduce((obj, prop) => obj[prop] || false, window)
  );
  // eslint-disable-next-line no-console
  missing.length && console.warn(`Browser lacks required features:\n\t${missing.join('\n\t')}`);

  return !missing.length;
};

/**
 * Because apparently it's still 2009... 👀
 */
export const fixBrowserQuirks = () => {
  fixViewportHeightInMobileSafari();
  workAroundAndroidsLaggyOrientationChanges();
  workAroundAndroidsVideoInitProblem();
};

/**
 * @see https://bugs.webkit.org/show_bug.cgi?id=141832
 */
const fixViewportHeightInMobileSafari = () => {
  if (browser?.os === 'iOS' && browser.name === 'ios')
    onRotation(() => {
      document.body.style.height = '100vh';
    });
  // TODO: Fix innerHeight change on keyboard open in iOS, see: https://blog.opendigerati.com/the-eccentric-ways-of-ios-safari-with-the-keyboard-b5aa3f34228d
};

const workAroundAndroidsLaggyOrientationChanges = () => {
  if (browser?.os === 'Android OS')
    onRotation(() => {
      Quagga.pause();
      setTimeout(() => Quagga.start(), 1000);
    });
};

/**
 * On Android, the camera feed doesn't load properly into a <video> element, the first time the the
 * page is loaded, necessitating a page refresh.
 */
const workAroundAndroidsVideoInitProblem = () => {
  const reloadPageWhenCameraFeedIsReady = () => {
    localStorage.setItem('visited', '1');
    const video = document.querySelector('video');
    video?.addEventListener('loadedmetadata', () => window.location.reload());
  };

  // We have to check for localStorage.visited, specifically, as another localStorage variable may
  // have already been set in this visit (for example, by the AnalyticsService).
  if (browser?.os === 'Android OS' && !localStorage.getItem('visited'))
    window.addEventListener('load', reloadPageWhenCameraFeedIsReady);
};
