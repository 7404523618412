import * as React from 'react';

const PhoneSVG = () => (
  <svg width={40} height={60} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x={1} y={1} width={38} height={58} rx={3} stroke="#000" strokeWidth={2} />
    <path
      d="M6 16v5M6 16h5M6 46v-5M6 46h5M12 24v13M24 24v13M28 24v13M18 25v11M34 16v5M34 
      16h-5M34 46v-5M34 46h-5"
      stroke="#000"
      strokeWidth={2}
      strokeLinecap="square"
    />
  </svg>
);

export default PhoneSVG;
