import 'react-app-polyfill/stable';
import './index.scss';

import { getOrientation } from 'o9n';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import analytics from './services/AnalyticsService';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { browserSupportsCSSGrid, fixBrowserQuirks, onRotation } from './util/browser';

// "Graceful" fallback for ancient browsers... 📟
if (!browserSupportsCSSGrid()) window.location.href = '/unsupported-browser.html';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

fixBrowserQuirks();
onRotation(() => analytics.logEvent('interaction', { rotation: getOrientation()?.type || '' }));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// TODO: Activate service workers (review implications for iOS).
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
