import ScannerError from '../types/abstract/ScannerError';
import { browser } from '../util/browser';

const isSafariOrIOS = () => browser?.os === 'iOS' || browser?.name === 'safari';

export default class CameraDeniedError extends ScannerError {
  constructor() {
    const message = `This app requires access to your device's camera, please ${
      isSafariOrIOS()
        ? "refresh the page and select 'Allow'"
        : 'adjust your settings and refresh the page'
    }`;
    super(message, { button: { text: 'Refresh the page', URL: window.location.href } });
  }
}
