import ScannerError from '../types/abstract/ScannerError';

export default class ScannerQualityError extends ScannerError {
  constructor() {
    const message = "The scanner's struggling!";
    const information =
      'Please ensure the barcode is in focus and try to fill the frame - or use Manual entry';
    super(message, { information, isSoft: true });
  }
}
