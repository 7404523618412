import ScannerError from '../types/abstract/ScannerError';

/**
 * This is ugly. But ugly situations can call for ugly solutions... 😔
 * https://stackoverflow.com/questions/63084076/ios13-getusermedia-not-working-on-chrome-and-edge/63092094#63092094
 */
const isIOS11to15 = () => {
  const iOSMajorVersion = parseInt(
    navigator.userAgent.match(/OS ([\d]{1,2})[_\d]{0,6} like Mac OS X/)?.pop() || '', // 🤢
    10
  );
  return iOSMajorVersion && iOSMajorVersion >= 11 && iOSMajorVersion < 15;
};

export default class UnsupportedBrowserError extends ScannerError {
  constructor() {
    let message =
      'Unfortunately, your browser does not support all of the features required by this site';
    let information = 'Please consider updating to a modern browser or installing the M&S App';

    if (isIOS11to15()) {
      message = 'Unfortunately, this browser is not supported by our site';
      information = 'Please use Safari or install the M&S App';
    }

    super(message, {
      information,
      button: { text: 'Install M&S app', URL: 'https://www.marksandspencer.com/c/mobile-apps' },
    });
  }
}
