import { ScannerComponentStatus, SearchStatus } from '../../types/enums/ScannerComponentStatus';
import { ScannerStatus } from '../../types/interfaces/Scanner';
import styles from './Overlay.module.scss';

enum Animation {
  Pulsing = 'pulsing',
  Swiping = 'swiping',
  Loading = 'loading',
  Error = 'error',
}

export default function Overlay({ status }: { status: ScannerComponentStatus }) {
  let animation = '';

  switch (status) {
    case ScannerStatus.Searching:
      animation = Animation.Pulsing;
      break;
    case ScannerStatus.Scanning:
      animation = Animation.Swiping;
      break;
    case ScannerStatus.Error:
    case SearchStatus.Error:
      animation = Animation.Error;
  }

  return (
    <div className={`${styles.overlay} ${styles[animation]}`} role="application">
      <div className={styles.window} />
      <div className={`${styles.corner} ${styles.topLeft}`} />
      <div className={`${styles.corner} ${styles.topRight}`} />
      <div className={`${styles.corner} ${styles.bottomRight}`} />
      <div className={`${styles.corner} ${styles.bottomLeft}`} />
    </div>
  );
}
