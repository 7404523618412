import mParticle, { MPConfiguration } from '@mparticle/web-sdk';

import { name as appName, version as appVersion } from '../../package.json';
import AnalyticsServiceInterface, { EventAttributes } from '../types/interfaces/AnalyticsService';

const MPARTICLE_EVENT_TYPE_OTHER = 8;
const isDevelopmentMode = window.location.host !== 'scan.marksandspencer.com';

class AnalyticsService implements AnalyticsServiceInterface {
  protected readonly eventNamePrefix = 'barcode_scanner_';
  protected readonly essentialLocalStorageVars = ['visited'];
  protected readonly isFirstVisit: boolean;
  protected readonly config: MPConfiguration = {
    appName,
    appVersion,
    isDevelopmentMode,
    logLevel: isDevelopmentMode ? 'warning' : 'none',
  };

  protected userConsentStatus: boolean | null;
  protected loaded = false;

  constructor() {
    this.userConsentStatus = JSON.parse(localStorage.getItem('cookie_consent') ?? 'null');
    this.isFirstVisit = !localStorage.length;

    this.userConsentStatus && this.load();
    this.logOrPersistAppLoad();
  }

  public getUserConsentStatus() {
    return this.userConsentStatus;
  }

  public setUserConsentStatus(userConsents: boolean) {
    this.userConsentStatus = userConsents;

    if (!this.userConsentStatus) {
      const varsToPersist = this.essentialLocalStorageVars.reduce(
        (map, key) => (localStorage.getItem(key) ? map.set(key, localStorage.getItem(key)) : map),
        new Map()
      );
      localStorage.clear();
      varsToPersist.forEach((value, key) => localStorage.setItem(key, value));
      localStorage.setItem('cookie_consent', 'false');
      return;
    }

    localStorage.setItem('cookie_consent', 'true');
    this.load();
    // Log the previously-persisted discovery method...
    this.logVisit(JSON.parse(localStorage.getItem('discovery') ?? 'null'));
  }

  public logEvent(name: string, data: EventAttributes = {}) {
    this.userConsentStatus &&
      mParticle.logEvent(this.eventNamePrefix + name, MPARTICLE_EVENT_TYPE_OTHER, data);
  }

  public logError(error: Error) {
    this.userConsentStatus &&
      mParticle.logError(`${this.eventNamePrefix}error`, { ...error, message: error.message });
  }

  protected logVisit(data: EventAttributes = {}) {
    this.userConsentStatus &&
      mParticle.logPageView(`${this.eventNamePrefix}visit`, { ...data, ua: navigator.userAgent });
  }

  protected load() {
    if (this.loaded) return;
    if (!process.env.REACT_APP_MPARTICLE_KEY)
      throw new Error(
        'REACT_APP_MPARTICLE_KEY env variable is required for mParticle registration.'
      );

    mParticle.init(process.env.REACT_APP_MPARTICLE_KEY as string, this.config);
    AnalyticsService.registerErrorHandler();
    this.loaded = true;
  }

  protected logOrPersistAppLoad() {
    // Ignore forced refreshes/navigation within the site.
    if (document.referrer.includes(window.location.hostname)) return;

    // We use QR codes to advertise the app in store. These load the URL with the discovery method
    // appended and, optionally, includes the ID of a specific store. For example: /qr/4297 would
    // indicate that the user scanned the QR code at our White City store.
    const matches = window.location.pathname.match(/^\/qr(?:\/(\d{1,9}))?/);
    const [qrCodeUsed, storeId] = matches ?? [null, null];
    let source;

    if (qrCodeUsed) {
      // Remove the /qr/{storeId} path (which is safe, as we know it's the landing path).
      window.history.replaceState({}, '', '/');
      source = 'QR';
    } else source = document.referrer || 'URL';

    const discoveryObject = storeId ? { source, storeId } : { source };

    // If it's the user's first visit and we've not had an opportunity to get their "cookie consent"
    // status yet, then we persist the discovery method (locally) until that time.
    if (this.userConsentStatus !== false && this.isFirstVisit)
      localStorage.setItem('discovery', JSON.stringify(discoveryObject));
    else if (this.userConsentStatus) this.logVisit(discoveryObject);
  }

  protected static registerErrorHandler() {
    window.addEventListener(
      'error',
      ({ constructor: { name }, message, filename, lineno, colno }) =>
        mParticle.logError({
          name,
          message,
          stack: `${filename}:${lineno}:${colno}`,
        })
    );
  }
}

const analytics = new AnalyticsService();
export default analytics;
