const quaggaConfig: QuaggaJSConfigObject = {
  inputStream: {
    name: 'Live',
    type: 'LiveStream',
    size: 1000,
  },
  locate: true,
  numOfWorkers: 2,
  frequency: 10,
  decoder: {
    readers: ['ean_8_reader', 'code_128_reader', 'ean_reader'],
    multiple: false,
  },
  debug: false,
};
export default quaggaConfig;
