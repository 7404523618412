export enum ScannerStatus {
  Inactive,
  Starting,
  Searching,
  Scanning,
  Error,
}

export enum BarcodeFormat {
  ean_8 = 'ean_8',
  ean_13 = 'ean_13',
  code_128 = 'code_128',
}

export interface Barcode {
  format: BarcodeFormat;
  value: string;
  confidence?: number;
}

export interface ScannerCallbacks {
  onStatusUpdate: (status: ScannerStatus) => void;
  onFound: (code: Barcode) => void;
  onError: (error: Error) => void;
}

export interface ScannerConfig {
  /** Float between 0 and 1, representing how reliable the scan was. */
  confidenceThreshold: number;
  /** The aggregate threshold for a collection of matching partial scans. */
  aggregateConfidenceThreshold: number;
  /** How long do we retain partial scans in the candidate pool, before discarding them. */
  aggregatePoolDuration: number;
  /** We use this value to "bridge the gap" between partial scans, to avoid a jumpy UI. */
  minScanDuration: number;
  /** The total time the scanner will scan for, without finding a code, before complaining. */
  maxScanDuration: number;
}

export default interface Scanner {
  start(): void;
  pause(): void;
  stop(): void;
}
