import Quagga from 'quagga';

import {
  CameraDeniedError,
  CameraRequiredError,
  ScannerStartupError,
  ScannerUnexpectedError,
  UnsupportedBrowserError,
} from '../errors';
import ScannerQualityError from '../errors/ScannerQualityError';
import ScannerError from '../types/abstract/ScannerError';
import Scanner, {
  Barcode,
  BarcodeFormat,
  ScannerCallbacks,
  ScannerConfig,
  ScannerStatus,
} from '../types/interfaces/Scanner';
import { browserHasFeatures } from '../util/browser';
import { idealCameraConstraints, identifyPrimaryCamera } from '../util/camera';
import quaggaConfig from './lib/quaggaConfig';

export default class BarcodeScanner implements Scanner {
  protected callbacks: ScannerCallbacks;
  /** The timeout ID which needs to be cleared with any status update. */
  protected scanTimeoutId?: number;
  /** Codes that are collected during a scan, if a good match isn't found straight away. */
  protected candidatePool: Candidate[] = [];
  /** The total amount of time spent scanning without finding a code. */
  protected fruitlessScanDuration = 0;
  protected lastScanTime: Date | null = null;
  protected config: ScannerConfig = {
    confidenceThreshold: 0.91,
    aggregateConfidenceThreshold: 2.6,
    aggregatePoolDuration: 10000,
    minScanDuration: 1200,
    maxScanDuration: 4850,
  };

  constructor(callbacks: ScannerCallbacks, config: Partial<ScannerConfig> = {}) {
    this.callbacks = callbacks;
    this.config = { ...this.config, ...config };
  }

  public async init(target?: Element) {
    this.updateStatus(ScannerStatus.Starting);

    if (!BarcodeScanner.browserHasRequiredFeatures()) {
      this.handleError(new UnsupportedBrowserError());
      return;
    }

    let camera;
    try {
      camera = await identifyPrimaryCamera();
    } catch (error) {
      this.handleError(new CameraRequiredError());
    }
    const config = {
      ...quaggaConfig,
      inputStream: {
        ...quaggaConfig.inputStream,
        target,
        constraints: {
          ...idealCameraConstraints,
          deviceId: camera?.deviceId ? camera.deviceId : undefined,
        },
      },
    };

    Quagga.init(config, (error: Error) => {
      if (error)
        this.handleError(
          error.name === 'NotAllowedError' ? new CameraDeniedError() : new ScannerStartupError()
        );
      else this.start();
    });
  }

  public start() {
    try {
      const callback = (result: QuaggaJSResultObject) => this.processCode(result);
      Quagga.onDetected(callback);
      Quagga.start();
      this.updateStatus(ScannerStatus.Searching);
      this.candidatePool = [];
      this.fruitlessScanDuration = 0;
    } catch (error) {
      this.handleError(new ScannerStartupError());
    }
  }

  public pause() {
    Quagga.offDetected('');
    Quagga.pause();
    this.updateStatus(ScannerStatus.Inactive);
  }

  public stop() {
    // We can safely suppress this error, as we know that Quagga cannot be reliably stopped and
    // restarted, so there's no need to spam our Error logging! 😬
    try {
      Quagga.offDetected('');
      Quagga.stop();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    this.updateStatus(ScannerStatus.Inactive);
  }

  protected clearScanStatusTimeout() {
    window.clearTimeout(this.scanTimeoutId);
    this.scanTimeoutId = undefined;
  }

  protected updateStatus(status: ScannerStatus) {
    this.clearScanStatusTimeout();
    this.callbacks.onStatusUpdate(status);
  }

  protected handleError(error: Error) {
    this.callbacks.onError(error instanceof ScannerError ? error : new ScannerUnexpectedError());
    this.updateStatus(ScannerStatus.Error);
  }

  /**
   * Keeps track of the total amount of time spent scanning without find a code and, if this value
   * exceeds the threshold defined by {@see ScannerConfig.maxScanDuration}, then the scanner throws
   * a "soft" (i.e. non-fatal) Error.
   */
  protected complainIfScanningForTooLongWithoutResult() {
    if (!this.lastScanTime) this.lastScanTime = new Date();
    else if (Date.now() - this.lastScanTime.getTime() > this.config.minScanDuration) {
      this.fruitlessScanDuration += this.config.minScanDuration;
      this.lastScanTime = new Date();
    }

    if (this.fruitlessScanDuration > this.config.maxScanDuration) {
      this.pause();
      this.handleError(new ScannerQualityError());
    }
  }

  /**
   * When the scanner finds something "barcode-like", it may not necessarily be accurate enough. In
   * which case, we pool the "candidates" until a modal value emerges.
   */
  protected processCode({ codeResult }: QuaggaJSResultObject) {
    // Maintain the "Scanning" status for a short duration, as the status could otherwise be jumping
    // between "Scanning" and "Searching" multiple times per second, creating a jumpy UI!
    this.updateStatus(ScannerStatus.Scanning);
    this.scanTimeoutId = window.setTimeout(
      () => this.updateStatus(ScannerStatus.Searching),
      this.config.minScanDuration
    );

    const confidence = BarcodeScanner.calculateConfidence(codeResult);
    const barcode = {
      value: codeResult.code,
      format: codeResult.format as BarcodeFormat,
      confidence,
    };

    this.candidatePool.push({ barcode, scanTime: new Date() });

    // eslint-disable-next-line no-console -- Essential for effective debugging! 🔎
    console.log(
      `${codeResult.code} (${Math.round(confidence * 1000) / 10}%, pool size: ${
        this.candidatePool.length
      }, duration: ${Math.round(this.fruitlessScanDuration / 1000)}s)`
    );

    // Is this code good enough, on its own, or with the support of the pool?
    if (confidence > this.config.confidenceThreshold || this.identifySuitableCandidate()) {
      this.pause();
      this.callbacks.onFound(barcode);
    } else this.complainIfScanningForTooLongWithoutResult();
  }

  protected static calculateConfidence({ decodedCodes }: QuaggaJSResultObjectCodeResult) {
    const sum = (a: number[]) => a.reduce((s, n) => s + n, 0);
    const errorRates = decodedCodes.map(n => n?.error).filter(n => n) as number[];

    return 1 - sum(errorRates) / (errorRates.length || 1);
  }

  protected removeExpiredCandidatesFromPool(): void {
    this.candidatePool = this.candidatePool.filter(
      candidate => Date.now() - candidate.scanTime.getTime() < this.config.aggregatePoolDuration
    );
  }

  /**
   * Assesses the candidate pool, to see if we have found enough samples of the same barcode in
   * order to select it with confidence.
   */
  protected identifySuitableCandidate(): Barcode | null {
    this.removeExpiredCandidatesFromPool();

    const confidenceTable = this.candidatePool.reduce(
      (freqTable, { barcode: { value, confidence } }) =>
        freqTable.set(value, freqTable.has(value) ? freqTable.get(value) + confidence : 0),
      new Map()
    );

    let bestCandidate = { value: '', confidenceSum: 0 };
    confidenceTable.forEach((confidenceSum, value) => {
      if (confidenceSum > bestCandidate.confidenceSum) bestCandidate = { value, confidenceSum };
    });

    return bestCandidate.confidenceSum > this.config.aggregateConfidenceThreshold
      ? this.candidatePool.find(({ barcode }) => barcode.value === bestCandidate.value)?.barcode ??
          null
      : null;
  }

  public static browserHasRequiredFeatures = () =>
    browserHasFeatures(
      'Worker',
      'HTMLCanvasElement',
      'Int8Array',
      'Blob',
      'URL.createObjectURL',
      'navigator.mediaDevices'
    );
}

type Candidate = { scanTime: Date; barcode: Barcode };
