/**
 * Used for getting the best-possible camera and configuration from `MediaDevices.getUserMedia()`.
 */
export const idealCameraConstraints = {
  width: { ideal: 4096 },
  height: { ideal: 2160 },
  facingMode: { ideal: 'environment' },
  focusMode: { ideal: 'continuous' },
  whiteBalance: { ideal: 'continuous' },
  exposureMode: { ideal: 'continuous' },
};

export const getCameras = async (): Promise<MediaDeviceInfo[]> => {
  const inputDevices = await window.navigator.mediaDevices.enumerateDevices();
  const cameras = inputDevices.filter(device => device.kind === 'videoinput');

  return cameras;
};

/**
 * Attempts to find the device's main, telephoto, back-facing camera.
 */
export const identifyPrimaryCamera = async (): Promise<MediaDeviceInfo | null> => {
  const cameras = await getCameras();
  if (!cameras.length) throw new Error('No camera found');

  const backFacingCameras = cameras.filter(camera => camera.label.includes('facing back'));

  // For some reason, on phones with multiple cameras, the last one is usually the main one...
  return backFacingCameras.length ? backFacingCameras[backFacingCameras.length - 1] : null;
};

/**
 * Returns the first video track that is currently being played, or null, if there aren't any...
 *
 * @param container Either the <video> element itself or its container. If no parameter is supplied,
 *                  then the entire document will be searched instead.
 */
export function getMainVideoTrack(container?: HTMLElement): MediaStreamTrack | null {
  const videoElement: HTMLVideoElement =
    container && container.tagName.toLowerCase() === 'video'
      ? (container as HTMLVideoElement)
      : (container ?? window.document).querySelectorAll('video')[0];

  if (videoElement?.srcObject) {
    const stream = videoElement.srcObject as MediaStream;
    return stream.getVideoTracks()[0] || null;
  }

  return null;
}
